import React from "react";
import Dashboard from "./dashboard/Dashboard";


function App() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default App;
